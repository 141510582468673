import {
  ToastId,
  UseToastOptions,
  createStandaloneToast,
} from '@chakra-ui/react';
import { RToast } from 'components';

interface ToastOptions {
  title: string;
  variant?: 'success' | 'error' | 'warning' | 'info';
  position?: UseToastOptions['position'];
  duration?: number;
}

const { toast } = createStandaloneToast();

export const useRToast = () => {
  const showToast = ({
    title,
    variant = 'info',
    position = 'bottom-right',
    duration = 5000,
  }: ToastOptions): ToastId => {
    return toast({
      position,
      duration,
      render: ({ onClose }) => (
        <RToast variant={variant} title={title} onClose={onClose} />
      ),
    });
  };

  const closeToast = (toastId: ToastId) => {
    toast.close(toastId);
  };

  return {
    showToast,
    closeToast,
  };
};
