/* eslint-disable react-hooks/exhaustive-deps */
import Head from 'next/head';
import { Flex, Heading, Grid, GridItem, useDisclosure } from '@chakra-ui/react';
import LogRocket from 'logrocket';

import {
  RGlobalLayout,
  RPageHeading,
  RLoadingAnimation,
  RGarageEmptyAlert,
} from 'components';
import routes from 'routes';
import { useGetGarage, useSessionData, useUserSettings } from 'hooks';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useUser } from 'context';
import newBg from 'public/images/backgrounds/new_background.webp';
import { Users } from 'api/generated/graphql';
import { FirstRace } from 'components/HomeScreen/FirstRace';
import { BuyTickets } from 'components/HomeScreen/BuyTickets';
import { UpcomingRaces } from 'components/HomeScreen/UpcomingRaces';
import { TheMarket } from 'components/HomeScreen/TheMarket';
import { MyGarage } from 'components/HomeScreen/MyGarage';
import { FeaturedRaceBanner } from 'components/HomeScreen/FeaturedRaceBanner';
import { MyTickets } from 'components/HomeScreen/MyTickets';
import { Activity } from 'components/HomeScreen/Activity';
import RewardsModal from 'components/RewardsModal/RewardsModal';

export const DefaultPage = () => {
  const router = useRouter();
  const { data, isLoading } = useGetGarage();
  const needsCar =
    !isLoading && (data?.getGarage.length === 0 || !data?.getGarage.length);

  const { tierUpdatedCars } = useUser();
  const { getUserSettings } = useUserSettings();
  const { sessionData } = useSessionData();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { isOpen: isRewardsModalOpen, onOpen: onRewardsModalOpen, onClose: onRewardsModalClose } = useDisclosure();

  const hasRaces = getUserSettings.data?.getUser?.totalRaces && getUserSettings.data?.getUser?.totalRaces > 0;

  useEffect(() => {
    if (tierUpdatedCars && tierUpdatedCars.length > 0) {
      router.push(routes.rewards());
    }
  }, [tierUpdatedCars]);

  useEffect(() => {
    const user = getUserSettings.data?.getUser as Users | undefined;

    if (!user || process.env.NEXT_PUBLIC_ENV !== 'prod') return;

    LogRocket.identify(user.id, {
      name: user.playerName || '',
      email: user.email,
      // Custom user variables
      country: user.countryFlag || '',
      totalEarnings: user.totalEarnings || 0,
      totalRaces: user.totalRaces || 0,
      walletChain: user.walletChain || '',
      blockPaidRaces: user.blockPaidRaces || false,
      testRacesAllowed: user.testRacesAllowed || false,
    });
  }, [getUserSettings.data]);

  useEffect(() => {
    const hasSeenRewardsModal = localStorage.getItem('hasSeenRewardsModal');
    
    if (getUserSettings.data && !getUserSettings.data?.getUser?.loginVerified && !hasSeenRewardsModal && sessionData) {
      localStorage.setItem('hasSeenRewardsModal', 'true');
      onRewardsModalOpen();
    }
  }, [getUserSettings.data, sessionData]);

  return (
    <>
      <RGlobalLayout
        onOpenAnyDrawer={isOpen}
        needsCar={needsCar}
        bgImageSrc={newBg.src}
        bgAnimationPulse={true}
      >
        <Head>
          <title>Racino.io</title>
        </Head>

        {/* <FeaturedRaceHeader /> */}
        <Flex
          flexDir="column"
          pb={{ base: "4rem", md: 0 }}
        >
          
        {isLoading && (
          <Flex justifyContent="center" alignItems="center" height="80%">
            <RLoadingAnimation />
          </Flex>
        )}

        <RGarageEmptyAlert show={!isLoading && needsCar} />

        

        {!isLoading && (
          <>
            <FeaturedRaceBanner />
            <Grid
              templateRows={hasRaces 
                ? { base: '164px 164px 125px 125px', md: 'repeat(3, 1fr)' }
                : { base: '156px 164px 164px 125px 125px', md: 'repeat(4, 1fr)' }
              }
              templateColumns={'repeat(2, 1fr)'}
              gap={4}
            >
              {/* FIRST RACE - Only show if user has no races */}
              {!hasRaces && (
                <GridItem
                  colSpan={2}
                  h={{ base: '156px', md: '218px' }}
                  position="relative"
                  id="getting-started"
                  borderRadius="8px"
                >
                  <FirstRace />
                </GridItem>
              )}

              {/* BUY TICKETS */}
              <GridItem
                position="relative"
                h={{ base: '164px', md: '224px' }}
                id="view-cars"
              >
                <BuyTickets />
              </GridItem>

              {/* MARKETPLACE LINK */}
              <GridItem
                position="relative"
                h={{ base: '164px', md: '224px' }}
                id="marketplace"
              >
                <UpcomingRaces />
              </GridItem>

              {/* TICKETS */}
              <GridItem
                position="relative"
                h={{ base: '164px', md: '224px' }}
                id="tickets"
              >
                <TheMarket />
              </GridItem>

              {/* FEATURED RACE */}
              <GridItem
                position="relative"
                h={{ base: '164px', md: '224px' }}
                id="featured-race"
              >
                <MyGarage />
              </GridItem>

              <GridItem
                position="relative"
                colSpan={{ base: 2, md: 1 }}
                h={{ base: '125px', md: '156px' }}
                id="activity"
              >
                <MyTickets />
              </GridItem>
              <GridItem
                position="relative"
                colSpan={{ base: 2, md: 1 }}
                h={{ base: '125px', md: '156px' }}
                id="activity"
              >
                <Activity />
              </GridItem>
            </Grid>
          </>
        )}

        <RewardsModal
          isOpen={isRewardsModalOpen}
          onClose={onRewardsModalClose}
        />
        </Flex>
      </RGlobalLayout>
    </>
  );
};

export default DefaultPage;
