import { Box, Flex, Grid, Text, FlexProps } from '@chakra-ui/react';
import { ReactNode } from 'react';

interface LevelBarProps extends FlexProps {
  title?: string;
  icon?: ReactNode;
  maxValue: number;
  currentValue: number;
  numberOfBars?: number;
}

export const LevelBar = ({
  title,
  icon,
  maxValue,
  currentValue,
  numberOfBars = 6,
  ...flexProps
}: LevelBarProps) => {
  const percentage = (currentValue / maxValue) * 100;
  const filledBars = (percentage / 100) * numberOfBars;
  const fullBars = Math.floor(filledBars);
  const partialBarWidth = (filledBars - fullBars) * 100;

  const getBarColor = (value: number) => {
    if (value <= 40) return 'bloodMoon.100';
    if (value <= 60) return 'secondaryYellow.100';
    return 'baltic.100';
  };

  const color = getBarColor(percentage);

  const bars = Array(numberOfBars)
    .fill('')
    .map((_, index) => (
      <Box
        key={index}
        w="full"
        h="0.125rem"
        position="relative"
        shadow="dark-lg"
      >
        <Box
          position="absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
          bg="gray.800"
        />
        {index < fullBars ? (
          <Box
            position="absolute"
            top={0}
            left={0}
            right={0}
            bottom={0}
            bg={color}
          />
        ) : index === fullBars && partialBarWidth > 0 ? (
          <Box
            position="absolute"
            top={0}
            left={0}
            width={`${partialBarWidth}%`}
            bottom={0}
            bg={color}
          />
        ) : null}
      </Box>
    ));

  return (
    <Flex gap="0.5rem" alignItems="center" w="full" {...flexProps}>
      {icon && (
        <Box w="0.75rem" h="0.75rem">
          {icon}
        </Box>
      )}
      <Flex gap="0.25rem" color="white.100" flexDir="column" w="full">
        {title && (
          <Flex w="full" justifyContent="space-between" alignItems="center">
            <Text
              textTransform="capitalize"
              fontFamily="heading"
              fontSize="0.625rem"
              lineHeight="0.75rem"
              noOfLines={1}
              flex="1"
            >
              {title}
            </Text>
          </Flex>
        )}
        <Grid
          w="full"
          gridTemplateColumns={`repeat(${numberOfBars}, 1fr)`}
          columnGap="2px"
        >
          {bars}
        </Grid>
      </Flex>
    </Flex>
  );
};
