import { useQuery, UseQueryResult } from '@tanstack/react-query';
import {
  CarTuningSimulation,
  SimulateTuningInput,
} from 'api/generated/graphql';
import { useGraphQLClient } from 'hooks';

interface SimulateCarTuningVariables {
  tunings: SimulateTuningInput;
  carId: string;
}

const useSimulateCarTuning = ({
  carId,
  tunings,
}: SimulateCarTuningVariables): UseQueryResult<{
  simulateCarTuning: CarTuningSimulation;
}> => {
  const { graphql, graphQLClient, hasToken } = useGraphQLClient();

  const simulateCarTuningGql = graphql(`
    query SimulateCarTuning($tunings: SimulateTuningInput!, $carId: String!) {
      simulateCarTuning(tunings: $tunings, carId: $carId) {
        stats {
          stats
          description
          bars
        }
      }
    }
  `);

  return useQuery({
    enabled: Boolean(hasToken && carId),
    queryKey: ['simulateCarTuning', carId, tunings],
    queryFn: async () => {
      return graphQLClient.request(simulateCarTuningGql, { carId, tunings });
    },
  });
};

export default useSimulateCarTuning;
